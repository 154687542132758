// frontend/src/firebase.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA09MFsMMwHqE6FMBILfGGbaAFNKqCVjMc",
  authDomain: "chat-app-76b32.firebaseapp.com",
  databaseURL:
    "https://chat-app-76b32-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "chat-app-76b32",
  storageBucket: "chat-app-76b32.appspot.com",
  messagingSenderId: "393240390238",
  appId: "1:393240390238:web:2d6466a90d85c3f24f22cf",
  measurementId: "G-6KHZRWM33J",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey: "BCqsnvYc1UAsxR9LMsZEBG-FFRRwmr3D_lTNVEyPG8xOQCeB3Rzj0cUz2QKFPxgd6Qc9X_-PcqHzagRIcz8jaZM",
    });
    console.log("FCM Token:", currentToken);
    return currentToken;
  } catch (error) {
    console.error("Error requesting FCM token:", error);
    throw error;
  }
};

export const onMessageListener = (callback) => {
  return onMessage(messaging, callback);
};

export default app;

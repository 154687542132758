import React, { useEffect } from "react";
import "./App.css";
import Homepage from "./Pages/Homepage";
import Chatpage from "./Pages/Chatpage";
import AdminDashboard from "./components/Admin/Dashboard";
import { Route, Switch } from "react-router-dom";
import { requestForToken, onMessageListener } from "./firebase";
import { useToast } from "@chakra-ui/toast";

function App() {
  const toast = useToast();

  useEffect(() => {
    // Request FCM token
    const getToken = async () => {
      try {
        const token = await requestForToken();
        if (token) {
          console.log("FCM Token:", token);
          // Optionally, send the token to your backend for storing
        }
      } catch (error) {
        toast({
          title: "Error fetching FCM token",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    };

    getToken();

    // Handle incoming messages
    onMessageListener((payload) => {
      console.log("Message received:", payload);
      // Display alert or handle notification here
      toast({
        title: "New Message",
        description: payload.notification.title,
        status: "info",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    });
  }, [toast]);

  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Homepage} exact />
        <Route path="/chats" component={Chatpage} />
        <Route path="/admin" component={AdminDashboard} />
      </Switch>
    </div>
  );
}

export default App;

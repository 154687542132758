import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Stack, Text, Button, useToast } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom"; // Assuming you use React Router for navigation
import { getSender } from "../config/ChatLogics";
import ChatLoading from "./ChatLoading";
import GroupChatModal from "./miscellaneous/GroupChatModal";
import { ChatState } from "../Context/ChatProvider";

const MyChats = ({ fetchAgain }) => {
  const [loggedUser, setLoggedUser] = useState();
  const [isBlocked, setIsBlocked] = useState(false); // State to track if account is blocked
  const [isAdmin, setIsAdmin] = useState(false); // State to track if user is admin or super admin
  const [newMessages, setNewMessages] = useState({}); // State to track new messages count for each chat

  const {
    selectedChat,
    setSelectedChat,
    user,
    chats,
    setChats,
    notification,
    setNotification,
  } = ChatState();
  const toast = useToast();
  const history = useHistory();

  const fetchChats = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.get("/api/chat", config);
      setChats(data);
      setIsBlocked(false); // Reset blocked state if fetch is successful
    } catch (error) {
      console.error("Error fetching chats:", error);
      toast({
        title: "Error Occurred!",
        description: "Failed to load chats.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });

      if (error.response && error.response.status === 403) {
        setIsBlocked(true); // Set blocked state if account is blocked
      } else if (error.response && error.response.status === 401) {
        // Handle unauthorized access, e.g., token expired or invalid
        history.push("/login"); // Redirect to login page
      }
    }
  };

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setLoggedUser(userInfo);
    setIsAdmin(
      userInfo && (userInfo.role === "admin" || userInfo.role === "super admin")
    );
    fetchChats();
    // eslint-disable-next-line
  }, [fetchAgain]);

  // Handle new message notifications
  useEffect(() => {
    const newMessagesCount = {};
    notification.forEach((notif) => {
      const chatId = notif.chat._id;
      if (!newMessagesCount[chatId]) {
        newMessagesCount[chatId] = 0;
      }
      newMessagesCount[chatId]++;
    });
    setNewMessages(newMessagesCount);
  }, [notification]);

  const handleChatClick = (chat) => {
    setSelectedChat(chat);
    // Clear new message notification for this chat
    setNotification(
      notification.filter((notif) => notif.chat._id !== chat._id)
    );
  };

  return (
    <Box
      d={{ base: selectedChat ? "none" : "flex", md: "flex" }}
      flexDir="column"
      alignItems="center"
      p={3}
      bg="white"
      w={{ base: "100%", md: "31%" }}
      borderRadius="lg"
      borderWidth="1px"
    >
      <Box
        pb={3}
        px={3}
        fontSize={{ base: "20px", md: "30px" }}
        fontFamily="Work Sans"
        d="flex"
        w="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        My Chats
        {isAdmin && (
          <GroupChatModal>
            <Button
              d="flex"
              fontSize={{ base: "12px", md: "10px", lg: "17px" }}
              rightIcon={<AddIcon />}
            >
              New Group Chat
            </Button>
          </GroupChatModal>
        )}
      </Box>
      <Box
        d="flex"
        flexDir="column"
        p={3}
        bg="#F8F8F8"
        w="100%"
        h="100%"
        borderRadius="lg"
        overflowY="auto"
      >
        {isBlocked ? (
          <Box bg="red.100" p={4} borderRadius="md" mb={4}>
            <Text fontSize="lg" color="red.800">
              Your account is blocked. Please contact support for assistance.
            </Text>
          </Box>
        ) : chats ? (
          <Stack spacing={4}>
            {chats.map((chat) => (
              <Box
                key={chat._id}
                onClick={() => handleChatClick(chat)}
                cursor="pointer"
                bg={selectedChat === chat ? "#38B2AC" : "white"}
                color={selectedChat === chat ? "white" : "black"}
                px={4}
                py={3}
                borderRadius="lg"
                borderWidth="1px"
                borderColor={selectedChat === chat ? "#38B2AC" : "#E8E8E8"}
                transition="all 0.3s ease"
                _hover={{
                  bg: "#F0F0F0",
                  borderColor: "#38B2AC",
                }}
                position="relative"
              >
                <Text fontSize="lg">
                  {!chat.isGroupChat
                    ? getSender(loggedUser, chat.users)
                    : chat.chatName}
                </Text>
                {chat.latestMessage && (
                  <Text fontSize="sm" color="gray.600">
                    <b>{chat.latestMessage.sender.name}:</b>{" "}
                    {chat.latestMessage.content.length > 50
                      ? `${chat.latestMessage.content.substring(0, 50)}...`
                      : chat.latestMessage.content}
                  </Text>
                )}
                {newMessages[chat._id] > 0 && (
                  <Box
                    as="span"
                    bg="blue.400"
                    color="white"
                    px={2}
                    py={1}
                    borderRadius="full"
                    fontSize="sm"
                    fontWeight="bold"
                    ml={2}
                    position="absolute"
                    top="50%"
                    right="15px"
                    transform="translateY(-50%)"
                  >
                    {newMessages[chat._id]}
                  </Box>
                )}
              </Box>
            ))}
          </Stack>
        ) : (
          <ChatLoading />
        )}
      </Box>
    </Box>
  );
};

export default MyChats;


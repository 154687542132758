import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  useToast,
  Button,
  Input,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
} from "@chakra-ui/react";
import "../../components/styles.css";

const Dashboard = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [newUser, setNewUser] = useState({ name: "", email: "", role: "user" });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const history = useHistory();

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo || userInfo.role !== "super admin") {
      history.push("/chats"); // Redirect to another route if not authorized
    }
  }, [history]);

  useEffect(() => {
    const fetchUsers = async () => {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        };
        const response = await axios.get("/api/admin/users", config);
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
        toast({
          title: "Error fetching users.",
          description: "There was an error fetching the user data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    fetchUsers();
  }, [toast]);

  const handleRoleChange = async (userId, newRole) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo.role !== "super admin" && newRole === "super admin") {
      toast({
        title: "Permission denied.",
        description: "Only super admins can assign the super admin role.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await axios.put(
        `/api/admin/users/${userId}`,
        { role: newRole },
        config
      );
      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, role: newRole } : user
          )
        );
        toast({
          title: "Role updated.",
          description: `User role has been updated to ${newRole}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating role:", error);
      toast({
        title: "Error updating role.",
        description: "There was an error updating the user role.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStatusChange = async (userId, newStatus) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await axios.put(
        `/api/admin/users/${userId}/status`,
        { status: newStatus },
        config
      );
      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, status: newStatus } : user
          )
        );
        toast({
          title: "Status updated.",
          description: `User status has been updated to ${newStatus}.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast({
        title: "Error updating status.",
        description: "There was an error updating the user status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to the first page on items per page change
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevNewUser) => ({ ...prevNewUser, [name]: value }));
  };

  const handleCreateNewUser = async () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const response = await axios.post("/api/admin/users", newUser, config);
      if (response.status === 201) {
        setUsers([...users, response.data]);
        toast({
          title: "User created.",
          description: "New user has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.error("Error creating user:", error);
      toast({
        title: "Error creating user.",
        description: "There was an error creating the user.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Pagination calculation
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const indexOfLastUser = currentPage * itemsPerPage;
  const indexOfFirstUser = indexOfLastUser - itemsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredUsers.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <Box className="dashboard-container">
      <Heading className="dashboard-heading">Admin Dashboard</Heading>

      <Flex justify="space-between" align="center" mb={4}>
        <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={10}>Show 10 rows</option>
          <option value={25}>Show 25 rows</option>
          <option value={50}>Show 50 rows</option>
        </Select>

        <Input
          placeholder="Search users..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="search-input"
          mr={4}
          ml={4}
        />
        <Button colorScheme="teal" onClick={onOpen} fontSize={10} pl={10} pr={10}>
          Create New User
        </Button>
      </Flex>

      <Box className="dashboard-table">
        <Table variant="striped" colorScheme="teal" size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Status</Th>
              <Th>Profile Pic</Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentUsers.map((user) => (
              <Tr key={user._id}>
                <Td>{user.name}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Select
                    value={user.role}
                    onChange={(e) => handleRoleChange(user._id, e.target.value)}
                    className={`role-select ${user.role}`}
                  >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                    <option value="super admin">Super Admin</option>
                  </Select>
                </Td>
                <Td>
                  <Button
                    colorScheme={user.status === "active" ? "green" : "red"}
                    onClick={() =>
                      handleStatusChange(
                        user._id,
                        user.status === "active" ? "blocked" : "active"
                      )
                    }
                  >
                    {user.status === "active" ? "Approved" : "Not Approved"}
                  </Button>
                </Td>
                <Td>
                  <img
                    src={user.pic}
                    alt="Profile Pic"
                    className="dashboard-img"
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      <Flex className="pagination-controls">
        {pageNumbers.map((number) => (
          <Button
            key={number}
            onClick={() => setCurrentPage(number)}
            disabled={currentPage === number}
            className={`pagination-button ${
              currentPage === number ? "active" : ""
            }`}
          >
            {number}
          </Button>
        ))}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                value={newUser.name}
                onChange={handleNewUserChange}
                placeholder="Enter name"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Email</FormLabel>
              <Input
                name="email"
                value={newUser.email}
                onChange={handleNewUserChange}
                placeholder="Enter email"
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Role</FormLabel>
              <Select
                name="role"
                value={newUser.role}
                onChange={handleNewUserChange}
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" onClick={handleCreateNewUser}>
              Create User
            </Button>
            <Button variant="ghost" onClick={onClose} ml={3}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Dashboard;

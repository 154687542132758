import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useToast, useDisclosure } from "@chakra-ui/react"; // Import useDisclosure from Chakra UI
import {
  Button,
  Input,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody, // Import DrawerBody from Chakra UI
} from "@chakra-ui/react";
import { Tooltip } from "@chakra-ui/react";
import { BellIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Avatar } from "@chakra-ui/avatar";
import { Spinner } from "@chakra-ui/spinner";
import ProfileModal from "./ProfileModal";
import UserListItem from "../userAvatar/UserListItem";
import ChatLoading from "../ChatLoading";
import NotificationBadge, { Effect } from "react-notification-badge";
import { getSender } from "../../config/ChatLogics";
import { ChatState } from "../../Context/ChatProvider";
import Cookies from "js-cookie";
import notificationSound from "../../sounds/ok.wav";
import { requestForToken, onMessageListener } from "../../firebase"; // Ensure firebase functions are imported

function SideDrawer() {
  const [search, setSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);

  const {
    setSelectedChat,
    user,
    notification,
    setNotification,
    chats,
    setChats,
  } = ChatState();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure(); // useDisclosure hook from Chakra UI
  const history = useHistory();
const badgeRef = useRef();

  // Ref for notification sound
  const notificationSoundRef = useRef(new Audio(notificationSound));

  const logoutHandler = () => {
    localStorage.removeItem("userInfo");
    history.push("/");
  };

  const handleSearch = async () => {
    if (!search) {
      toast({
        title: "Please enter something in the search",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top-left",
      });
      return;
    }

    try {
      setLoading(true);

      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };

      const { data } = await axios.get(`/api/user?search=${search}`, config);

      setLoading(false);
      setSearchResult(data);
    } catch (error) {
      toast({
        title: "Error Occurred!",
        description: "Failed to load the search results",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  const accessChat = async (userId) => {
    try {
      setLoadingChat(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      };
      const { data } = await axios.post(`/api/chat`, { userId }, config);

      if (!chats.find((c) => c._id === data._id)) {
        setChats([data, ...chats]);
      }
      setSelectedChat(data);
      setLoadingChat(false);
      onClose();
    } catch (error) {
      toast({
        title: "Error fetching the chat",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-left",
      });
    }
  };

  // Effect to play notification sound and set a cookie when new notifications arrive
  useEffect(() => {
    if (notification.length > 0) {
      const lastNotificationId = Cookies.get("lastNotificationId");

      // Check if the last notification ID in the cookie matches the latest notification ID
      if (lastNotificationId !== notification[0]._id) {
        // Play notification sound
        notificationSoundRef.current
          .play()
          .catch((error) =>
            console.error("Notification sound play error:", error)
          );

        // Set notification cookie with the latest notification ID
        Cookies.set("lastNotificationId", notification[0]._id);

        // Trigger mobile alert
        if ("Notification" in window) {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              new Notification("New message received", {
                body: "You have a new message in your chat.",
                icon: "/logo png.jpg",
              });
            }
          });
        }
      }
    }
  }, [notification]);

  // Initialize Firebase Messaging for web notifications
  useEffect(() => {
    requestForToken()
      .then((currentToken) => {
        console.log("FCM Token getting success:", currentToken);

        // Add an event listener to handle incoming messages
        onMessageListener((payload) => {
          console.log("Message received:", payload);

          // Play notification sound
          notificationSoundRef.current
            .play()
            .catch((error) =>
              console.error("Notification sound play error:", error)
            );

          // Display notification logic here (e.g., using Notification API)
          new Notification(payload.notification.title, {
            body: payload.notification.body,
            icon: payload.notification.icon,
          });

          // Optionally update state or trigger other actions based on the notification
        });
      })
      .catch((error) => {
        console.error("Error initializing Firebase Messaging:", error);
      });
  }, []);

  // Ensure user is defined before rendering
  if (!user) {
    return null; // Or handle loading state appropriately
  }

  return (
    <>
      <Box
        d="flex"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        w="100%"
        p="5px 10px 5px 10px"
        borderWidth="5px"
      >
        {(user.role === "admin" || user.role === "super admin") && (
          <Tooltip label="Search Users to chat" hasArrow placement="bottom-end">
            <Button variant="ghost" onClick={onOpen}>
              <i className="fas fa-search"></i>
              <Text d={{ base: "none", md: "flex" }} px={4}>
                Search User
              </Text>
            </Button>
          </Tooltip>
        )}
        <Text fontSize="1xl" fontFamily="Work sans" fontWeight="bold">
          Masteredu Chat
        </Text>

        <div>
          <Menu>
            <MenuButton p={1}>
              <NotificationBadge
                count={notification.length}
                effect={Effect.SCALE}
                ref={badgeRef}
              />

              <BellIcon fontSize="2xl" m={1} />
            </MenuButton>
            <MenuList pl={2}>
              {!notification.length && "No New Messages"}
              {notification.map((notif) => (
                <MenuItem
                  key={notif._id}
                  onClick={() => {
                    setSelectedChat(notif.chat);
                    setNotification(notification.filter((n) => n !== notif));
                  }}
                >
                  {notif.chat.isGroupChat
                    ? `New Message in ${notif.chat.chatName}`
                    : `New Message from ${getSender(user, notif.chat.users)}`}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton as={Button} bg="white" rightIcon={<ChevronDownIcon />}>
              <Avatar
                size="sm"
                cursor="pointer"
                name={user.name}
                src={user.pic}
              />
            </MenuButton>
            <MenuList>
              <ProfileModal user={user}>
                <MenuItem>My Profile</MenuItem>
              </ProfileModal>
              <MenuDivider />
              <MenuItem onClick={logoutHandler}>Logout</MenuItem>
            </MenuList>
          </Menu>
        </div>
      </Box>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">Search Users</DrawerHeader>
          <DrawerBody>
            <Box d="flex" pb={2}>
              <Input
                placeholder="Search by name or email"
                mr={2}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button onClick={handleSearch}>Go</Button>
            </Box>
            {loading ? (
              <ChatLoading />
            ) : (
              searchResult?.map((user) => (
                <UserListItem
                  key={user._id}
                  user={user}
                  handleFunction={() => accessChat(user._id)}
                />
              ))
            )}
            {loadingChat && <Spinner ml="auto" />}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SideDrawer;
